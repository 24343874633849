import { useState } from "react"
import { redirectToThnqPage } from "./redirectToThnqPage"
import { useContextValues } from "../context/NavBarContext"
import { additionalInfoUrl } from "../../services/reqUrl.service"
import { handleFormSubmit } from "./handleFormSubmit"
import { isNotEmpty, isObjectNonEmpty } from "./validate"
// Error message
interface IError {
  siteMapDetailsError: string
  referenceWebsiteError: string
}

// Form values
interface IForm {
  siteMapDetails: string
  referenceWebsite: string
}

export const additionalInfoFormCtrlsAndVal = () => {
  const { currentContactFormDetails } = useContextValues()
  const { submitRequest } = handleFormSubmit()
  const [errorMessage, setErrorMessage] = useState<IError>({
    siteMapDetailsError: "",
    referenceWebsiteError: "",
  })
  const [formValue, setFormValue] = useState<IForm>({
    siteMapDetails: "",
    referenceWebsite: "",
  })

  const getErrorMessage = (value, msg) => {
    let errorMsg = ""
    if (!isNotEmpty(value)) {
      errorMsg = msg
    } else if (value.length < 30) {
      errorMsg = "Please provide at least 30 characters"
    } else {
      errorMsg = ""
    }
    return errorMsg
  }

  const validate = (name, value) => {
    //A function to validate each input values
    let formsError = { ...errorMessage }

    switch (name) {
      case "siteMapDetails":
        formsError.siteMapDetailsError = getErrorMessage(
          value,
          "Please provide sitemap details for your website"
        )
        break
      case "referenceWebsite":
        formsError.referenceWebsiteError = getErrorMessage(
          value,
          "Please provide reference or competitor websites details"
        )
        break
      default:
        break
    }
    setErrorMessage(formsError)
    return formsError
  }

  //A method to handle form inputs
  const handleChange = event => {
    //To stop default events
    event.persist()

    let name = event.target.name
    let value = event.target.value
    validate(name, value)
    //Let's set these values in state
    setFormValue(prevValue => ({
      ...prevValue,
      [name]: value,
    }))
  }

  // Handel Submit
  const validateAndSubmit = async (pathName = "") => {
    let formsError = { ...errorMessage }
    for (let key of Object.keys(formValue)) {
      if (!isNotEmpty(formValue[key])) {
        formsError[`${key}Error`] = "This field is required"
      } else if (formValue[key].length < 30) {
        formsError[`${key}Error`] = "Please provide at least 30 characters"
      } else {
        formsError[`${key}Error`] = ""
      }
    }
    setErrorMessage(formsError)
    if (isObjectNonEmpty(formsError)) {
      return
    }
    await submitAndRedirect(pathName)
  }

  const submitAndRedirect = async pathName => {
    await submitRequest(additionalInfoUrl, {
      ...formValue,
      ...currentContactFormDetails,
    })
    redirectToThnqPage(pathName)
  }

  const handleSubmit = async (event, pathName = "") => {
    if (event) event.preventDefault()
    await validateAndSubmit(pathName)
  }

  return {
    formValue,
    setFormValue,
    errorMessage,
    setErrorMessage,
    handleChange,
    handleSubmit,
    validate,
  }
}
