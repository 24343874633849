import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import React from "react"

interface GatsbyImageType extends IGatsbyImageData {
    extension: string
    publicURL: string
}

type EcoSystemType = {
    title: Array<Array<string>>
    left_div: Array<{
        title: string
        children: Array<{
            text: string
            techImage: GatsbyImageType
        }>
    }>
    right_div: Array<{
        title: string
        children: Array<{
            text: string
            techImage: GatsbyImageType
        }>
    }>
}

type propType = {
    ecosystem: EcoSystemType
}

const EcoSystem = ({ ecosystem }: propType) => {
    return (
        <section className="py-10 md:py-12 lg:py-[3.438rem] overflow-hidden">
            <div className="container">
                <div className="title-sec text-center md:max-w-[47.5rem] m-auto">
                    <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">
                        {ecosystem.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        <span className="inline-block text-orangecol">.</span></h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-y-[3.125rem] md:gap-x-14 lg:gap-x-20 gap-x-[6.875rem] mt-[3.25rem] lg:mt-[2rem] items-start eco-system lg:max-w-[1000px] mx-auto">

                    <div className="flex flex-wrap items-start justify-start gap-x-[4.375rem]">
                        {ecosystem.left_div.map(({ title, children }, idx) => (
                            <div key={`left-${idx}`} className="mb-8 lg:mb-[4.25rem]">
                                <h5 className="w-full text-base lg:text-lg font-gorditamedium leading-6 lg:leading-[1.813rem] mb-4 lg:mb-[1.25rem]">{title}</h5>
                                <div className="img-section flex flex-wrap items-start gap-x-5 gap-y-5 lg:gap-y-[1.875rem]">
                                    {children.map(({ text, techImage }, idx) => {
                                        return (
                                            <div key={`child-${idx}`} className="flex flex-col gap-[0.625rem]">
                                                <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] max-md:w-[4.25rem] max-md:h-[4.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem] justify-center">
                                                    {(techImage?.extension !== "svg")
                                                        ? <GatsbyImage image={getImage(techImage)!} alt={text} objectFit="contain" />
                                                        : <img src={techImage.publicURL} alt="" loading="lazy" />}
                                                </figure>
                                                <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem] max-w-[5.625rem]">
                                                    {text}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-wrap items-start justify-start gap-x-[4.375rem]">
                        {ecosystem.right_div.map(({ title, children }, idx) => (
                            <div key={`right-${idx}`} className="mb-10 lg:mb-[4.25rem]">
                                <h5 className="w-full text-base lg:text-lg font-gorditamedium leading-6 lg:leading-[1.813rem] mb-4 lg:mb-[1.25rem]">{title}</h5>
                                <div className="img-section flex flex-wrap items-start gap-x-5 gap-y-5 lg:gap-y-[1.875rem]">
                                    {children.map(({ text, techImage }, idx) => {
                                        return (
                                            <div key={`child-${idx}`} className="flex flex-col gap-[0.625rem]">
                                                <figure className="py-[1.438rem] px-[1.375rem] bg-[#F8F7F2] rounded-[1.25rem] max-md:w-[4.25rem] max-md:h-[4.25rem] w-[5.625rem] h-[5.625rem] inline-flex mb-[0.625rem] justify-center">
                                                    {(techImage?.extension !== "svg")
                                                        ? <GatsbyImage image={getImage(techImage)!} alt={text} objectFit="contain" />
                                                        : <img src={techImage.publicURL} alt="" loading="lazy" />}
                                                </figure>
                                                <span className="text-xs md:text-sm leading-4 md:leading-[1.188rem] max-w-[5.625rem]">{text}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EcoSystem