import React, { ReactNode, useState } from "react"
import { useContextValues } from "../context/NavBarContext"
import { additionalInfoFormCtrlsAndVal } from "../helpers/additionalInfoCtrlAndVal"
import { TextArea } from "./FormFields"

type Props = {
  spacingCss?: string
  submitBtnCss?: string
  formCss?: string
  darkTheme?: boolean
  children?: ReactNode
  textAreaCss?: string
}
type DisplayComponent = "siteMapDetails" | "referenceWebsite"
export default function AdditionalInfoForm({
  spacingCss,
  submitBtnCss,
  formCss,
  textAreaCss,
  darkTheme = false,
  children,
}: Props) {
  const { currentPath } = useContextValues()
  const { handleChange, errorMessage, formValue, handleSubmit, validate } =
    additionalInfoFormCtrlsAndVal()
  const [currentQuestionCompnt, setCurrentQuestionCompnt] =
    useState<DisplayComponent>("siteMapDetails")

  const getTitle = (name: DisplayComponent) => {
    switch (name) {
      case "siteMapDetails":
        return "What will be your sitemap, which means what kind of pages will be required on your new website?"
      case "referenceWebsite":
        return "Do you have any reference websites that you like design-wise OR a Competitor website url?"
      default:
        break
    }
  }

  const getSubTitle = (name: DisplayComponent) => {
    switch (name) {
      case "siteMapDetails":
        return "i.e homepage, product page/Services, about us, etc"
      case "referenceWebsite":
        return "so we can understand your design expectations"
      default:
        break
    }
  }

  const getPlaceholder = (name: DisplayComponent) => {
    switch (name) {
      case "siteMapDetails":
        return "Describe your sitemap"
      case "referenceWebsite":
        return "Describe reference website url OR competitor websites details"
      default:
        break
    }
  }
  const getCss = (property, darkTheme, customClasses = "") => {
    switch (property) {
      case "form":
        return darkTheme
          ? ``
          : `contact-form-main contact-form-page ${
              customClasses || "bg-white-200 rounded-xl p-6 md:px-10 px-6 pb-10"
          }`
      case "title":
        return darkTheme
          ? `text-lg resize-none w-full text-white pb-6 pr-3 bg-transparent`
          : `text-[1.5rem] md:leading-[2rem] leading-[1.8rem] font-blancotrialbold bg-[#fff] rounded-xl`
      case "subTitle":
        return darkTheme
          ? `pb-3 border-0 border-b border-b-[rgba(255,255,255,0.17)] focus:border-0 focus:border-b focus:outline-none foucs:border-b-[rgba(255,255,255,0.17)] focus-visible:border-0 focus-visible:outline-none foucs-visible:border-b-[rgba(255,255,255,0.17)] focus-visible:border-b`
          : ""
      case "textArea":
        return darkTheme
          ? ` ${customClasses}`
          : `text-black ${
              customClasses ||
          "py-4 px-7 max-[1440px]:py-[0.75rem] bg-transparent rounded-md border border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
          }`
      case "submitBtn":
        return darkTheme
          ? `text-white bg-primary text-sm text-center rounded-3xl px-3 py-3 min-w-[10rem] font-gorditamedium hover:bg-orange-500 hover:text-white transition-all`
          : customClasses
      default:
        return ""
    }
  }

  const handleNextOrSubmitClick = e => {
    if (currentQuestionCompnt === "siteMapDetails") {
      let errorMessages = validate(
        currentQuestionCompnt,
        formValue.siteMapDetails
      )
      if (!errorMessages.siteMapDetailsError) {
        setCurrentQuestionCompnt("referenceWebsite")
      }
    } else {
      handleSubmit(e, currentPath)
    }
  }
  return (
    <>
      {children}
      <form className={getCss("form", darkTheme, formCss)}>
        <div
          className={`relative flex justify-between md:flex-nowrap flex-wrap`}
        >
          <div className=" w-full md:pb-0 pb-5">
            <h3 className={getCss("title", darkTheme)}>
              {getTitle(currentQuestionCompnt)}
            </h3>
            <p className={`mb-4 ${getCss("subTitle", darkTheme)}`}>
              {getSubTitle(currentQuestionCompnt)}
              <span className="bg-orangecol w-1.5 h-1.5 rounded-full inline-block ml-1"></span>
            </p>
            <div
              className={`relative flex justify-between ${spacingCss} pb-0 md:flex-nowrap flex-wrap`}
            >
              <div className=" w-full md:pb-0 pb-5">
                <TextArea
                  fieldCss={`align-top h-60 max-[1440px]:h-24 resize-none text-lg w-full ${getCss(
                    "textArea",
                    darkTheme,
                    textAreaCss
                  )}`}
                  errorCss="text-sm text-red absolute lg:bottom-0 bottom-0"
                  formValue={formValue}
                  handleChange={handleChange}
                  errorMessage={errorMessage}
                  name={currentQuestionCompnt}
                  placeholder={getPlaceholder(currentQuestionCompnt)}
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          className={`mt-2 ${getCss("submitBtn", darkTheme, submitBtnCss)}`}
          onClick={handleNextOrSubmitClick}
        >
          {currentQuestionCompnt === "siteMapDetails" ? "Next" : "Submit"}
        </button>
      </form>
    </>
  )
}
