import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { Dispatch, SetStateAction } from "react"
import { Currency } from "../FormFields/Budget"
import ContactForm from "./ContactForm"

type Props = {
  toggleModal: Dispatch<SetStateAction<boolean>>
  currency?: Currency
  formTitle?: string
}
const ModalForm = ({
  toggleModal,
  currency = "USD",
  formTitle = "Share your website requirements",
}: Props) => {
  return (
    <>
      <button
        onClick={() => toggleModal(prev => !prev)}
        className="top-[-15px] right-[-15px] absolute inline-white text-white bg-primary text-base rounded-full w-10 h-10 hover:bg-orange-500 hover:text-white transition-all duration-500"
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>
      <ContactForm
        currency={currency}
        spacingCss="md:pb-8"
        submitBtnCss="text-white bg-primary text-sm text-center rounded-3xl px-6 py-3 sm:mb-0 mb-4 sm:mr-3 sm:min-w-[10rem] sm:w-auto w-full font-gorditamedium hover:bg-orange-500 hover:text-white transition-all"
        formTitle={formTitle}
      />
    </>
  )
}
export default ModalForm
