import { graphql, HeadFC } from "gatsby"
import React, { useEffect, useState } from "react"
import SEO from "../components/SEO"
import HireDeveloper from "../components/PPC/HireDevPpc"
import useSiteMetadata from "../hooks/useSiteMetadata"
import Contact from "../components/Contact"
import Modal from "../components/PPC/Modal"
import ModalForm from "../components/PPC/ModalForm"
import { useContextValues } from "../context/NavBarContext"

const HiringPage = ({
    data: {
        hiringJson: post,
    },
}) => {
    const { changeCurrentPath } = useContextValues()
    const [showModal, setShowModal] = useState(false)

    useEffect(() => changeCurrentPath(location.pathname), [])

    return (
        <>
            <HireDeveloper {...post} />
            <Contact />
            <Modal showModal={showModal}>
                <ModalForm
                    currency="INR"
                    toggleModal={setShowModal}
                    formTitle="Share your requirements"
                />
            </Modal>
        </>
    )
}

export const query = graphql`
query ($slug: String!) {
    hiringJson(slug: { eq: $slug }) {
        slug
        seo_title
        seo_description
        title_1
        subtitle_1
        description_1
        title_2
        title_3
        title_4
        title_5
        title_6
        CTO_text
        title_7
        title_8
        faqs {
            key
            question
            answer
        }
        build_team_points {
            pointImage {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
                publicURL
            }    
            title
            description
        }
        team {
            personImage {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            imageAlt
            name
            designation
            experience
        }
        ecosystem {
            title
            left_div {
                title
                children {
                    text
                    techImage {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                        publicURL
                        extension
                    }
                }
            }
            right_div {
                title
                children {
                    text
                    techImage {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                        publicURL
                        extension
                    }
                }
            }
        }
        guide {
            title
            tableOfContent
            content {
                overview {
                    title
                    description
                    details {
                        title
                        description
                    }
                }
                stepsToHire {
                    title
                    description
                    details {
                        title
                        description
                    }
                }
                hardSkills {
                    title
                    description
                    details {
                        title
                        points {
                            subtitle
                            text
                        }
                    }
                }
                softSkills {
                    title
                    description
                    details {
                        title
                        description
                    }
                }
                interviewQuestions {
                    title
                    qnas {
                        question
                        answer {
                            text
                            points
                        }
                    }
                }
            }
        }
    }
  }
`
export default HiringPage

export const Head: HeadFC = ({ location, data }) => {
    const { siteUrl } = useSiteMetadata()

    const { seo_title, seo_description } = data["hiringJson"]

    return (
        <SEO
            title={seo_title}
            canonical={`${siteUrl}${location.pathname}`}
            description={seo_description}
        />
    )
}
