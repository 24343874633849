import React, { useEffect, useState } from "react"
import Faq from "../../../components/PPC/ReactDevPpc/Faq"
import Seo from "../../../components/SEO"
import { useContextValues } from "../../../context/NavBarContext"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BatchIcon1 from "../../../assets/img/batch-icon-1.inline.svg"
import BatchIcon2 from "../../../assets/img/batch-icon-2.inline.svg"
import BatchIcon3 from "../../../assets/img/batch-icon-3.inline.svg"
import BatchIcon4 from "../../../assets/img/batch-icon-4.inline.svg"

import ArrowWhite from "../../../assets/img/arrow-right.webp"
import TestimonialSlider from "../../DashboardTestimonials/TestimonialSlider"
import StarImage from "../../../assets/img/star.png"
import ClutchLogo from "../../../assets/img/clutch-new.png"
import EcoSystem from "../../EcoSystemComponent"
import SwiperSlider from "../../SwiperSlider"
import { SwiperSlide } from "swiper/react"
import BannerSlider from "../../BannerSlider"

const HireReactDevelopersUSA = (post) => {
    const { changeCurrentPath } = useContextValues()
    const [elementInViewPort, setElementInViewPort] = useState(1)

    const handleScroll = () => {
        const sectionIds = ['section1', 'section2', 'section3', 'section4', 'section5']
        sectionIds.map((id, idx) => {
            const item = document.getElementById(id)
            // 65 px is subtracted because we have set scroll-margin to 4rem
            if (window.pageYOffset >= item!.offsetTop - 65) {
                setElementInViewPort(idx + 1)
            }
        })
    };

    useEffect(() => {
        changeCurrentPath(location.pathname)
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [changeCurrentPath]);
    return (
        <>
            <section className="pt-[7.625rem] pb-20 md:pt-24 lg:pt-40 lg:pb-28 bg-[#F8F7F3]">
                <div className="container">
                    <div className="max-w-[51.25rem] mx-auto text-center">
                        <h1 className="text-black text-[2.25rem] md:text-[2.75rem] lg:text-[3.25rem] leading-[3.25rem] lg:leading-[3.981rem] pb-[1.688rem] md:pb-9 lg:pb-10 font-blancomedium">
                            {post.title_1.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h1>
                        <p className="text-base md:text-lg">
                            {post.description_1}
                        </p>
                        {post.subtitle_1 && <p className="text-[18px] md:text-[22px] leading-[27px] md:leading-[36px] mt-[30px] md:mt-[50px] font-gorditamedium">{post.subtitle_1}</p>}
                        <Link
                            to="/contact-us/"
                            className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base leading-[1.313rem] rounded-3xl pl-6 py-1 pr-1 font-gorditamedium group ease-in duration-300 mt-[40px] mb-[30px] md:mt-[50px] md:mb-[40px]"
                        >
                            Share your requirements
                            <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-10 w-10 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                />
                            </span>
                        </Link>
                        <ul className="flex flex-wrap justify-center gap-x-9 gap-y-4 text-xs text-black leading-[0.809rem] font-gorditaregular pt-1 pb-[1.375rem] relative after:content-[''] after:block after:absolute after:bottom-0 after:left-0 after:right-0 after:w-full after:h-[0.063rem] after:bg-gradient-to-r from-[#C9C9C900] from-10% via-[#C9C9C9] via-30% to-[#C9C9C900] to-90%">
                            <li className="relative pl-5">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-3.5 w-3.5 absolute top-[-0.125rem] left-0"></span> 7-day free trial
                            </li>
                            <li className="relative pl-5">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-3.5 w-3.5 absolute top-[-0.125rem] left-0"></span> On board within 48 hours
                            </li>
                            <li className="relative pl-5">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-3.5 w-3.5 absolute top-[-0.125rem] left-0"></span> Flexible scale up or down
                            </li>
                            <li className="relative pl-5">
                                <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-3.5 w-3.5 absolute top-[-0.125rem] left-0"></span> Simple contracts
                            </li>
                        </ul>

                        <div className="relative after:content-[''] after:block after:absolute after:bottom-0 after:left-0 after:right-0 after:w-full after:h-[0.063rem] after:bg-gradient-to-r from-[#C9C9C900] from-10% via-[#C9C9C9] via-30% to-[#C9C9C900] to-90%"></div>
                        <div className="text-center text-xs leading-[0.938rem] mt-[1.625rem] flex items-center gap-2 flex-wrap align-middle justify-center">
                            <span className="text-sm md:text-base">4.9/5</span>
                            <span className="flex flex-nowrap items-center gap-[0.188rem]">
                                <img
                                    src={StarImage}
                                    alt=""
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt=""
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt=""
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt=""
                                    className="max-w-[0.75rem]"
                                />
                                <img
                                    src={StarImage}
                                    alt=""
                                    className="max-w-[0.75rem]"
                                />
                            </span>
                            <span>based on <em className="font-gorditamedium not-italic">41</em> verified reviews</span>
                            <img
                                src={ClutchLogo}
                                alt="Clutch"
                                className="max-w-[84px]"
                            />
                        </div>
                    </div>
                    <div className="banner-logo-slider mt-[3.188rem] lg:mt-[4.625rem] flex justify-center">
                        <BannerSlider bannerCount={5} sliderClassWeb="!justify-center" swiperButtons="bullets" />
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="title-sec text-center md:pt-[6.875rem] pt-20 md:max-w-[47.5rem] m-auto md:pb-10 pb-9">
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium  ">
                            {post.title_2.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="btch-big bg-[#E8E3FF] inline-block rounded-[6.25rem] text-[0.875rem] italic mt-[1.875rem] md:mt-[2.313rem] font-gorditamedium text-sm sm:text-base  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]">Consider <span className="font-gorditamedium italic">Techuz as your pitcrew</span> by your side to win your product success race.</p>
                    </div>

                    <div className="flex flex-wrap gap-5 md:gap-[1.938rem] justify-center">
                        {post.team.map(({ name, designation, experience, personImage, imageAlt }, idx) => {
                            personImage = getImage(personImage)
                            return (
                                <div key={idx} className="rounded-[1.25rem] border border-solid border-[#DFDCD3] overflow-hidden min-w-[14.375rem] hidden sm:block">
                                    <figure className="bg-[#F8F7F3] pt-[1.625rem] px-10 flex items-end justify-center h-[12.313rem]">
                                        <GatsbyImage image={personImage} alt={imageAlt} objectFit="contain" className="max-w-[143px] max-h-[182px]" />
                                    </figure>
                                    <div className="px-5 pt-[1.375rem] pb-[1.625rem]">
                                        <h4 className="text-lg font-gorditamedium leading-normal mb-[0.563rem]">{name}</h4>
                                        <span className="block text-sm leading-normal mb-1">{designation}</span>
                                        <p className="italic text-xs leading-normal text-[#A0A0A0] font-gorditamedium">{experience}</p>
                                    </div>
                                </div>
                            )
                        })}
                        <SwiperSlider slidesPerView={1.5} className="sm:hidden">
                            {post.team.map(({ name, designation, experience, personImage, imageAlt }, idx) => {
                                personImage = getImage(personImage)
                                return (
                                    <SwiperSlide key={idx}>
                                        <div className="rounded-[1.25rem] border border-solid border-[#DFDCD3] overflow-hidden">
                                            <figure className="bg-[#F8F7F3] pt-[1.625rem] px-10 flex items-end justify-center h-[12.313rem]">
                                                <GatsbyImage image={personImage} alt={imageAlt} className="max-w-[143px]" />
                                            </figure>
                                            <div className="px-5 pt-[1.375rem] pb-[1.625rem]">
                                                <h4 className="text-lg font-gorditamedium leading-normal mb-[0.563rem]">{name}</h4>
                                                <span className="block text-sm leading-normal mb-1">{designation}</span>
                                                <p className="italic text-xs leading-normal text-[#A0A0A0] font-gorditamedium">{experience}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </SwiperSlider>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[1.875rem] lg:gap-y-10 md:gap-x-10 lg:gap-x-[4.5rem] mt-10 md:mt-[3.125rem]">
                        {post.build_team_points.map(({ pointImage, title, description }, idx) => {

                            return (
                                <div key={idx} className="">
                                    <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                                        <img loading="lazy" src={pointImage.publicURL} />
                                    </figure>
                                    <span className={`btch-big inline-block rounded-[6.25rem] ${title[0]}  text-[0.875rem] italic font-gorditamedium text-xs sm:text-sm  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px] mb-2 md:mb-5`}>
                                        {title[1]}
                                    </span>
                                    <p className="text-[0.938rem] md:text-base leading-[1.625rem]">
                                        {description}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            {/* gradient featured section */}
            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] bg-center rounded-[1.25rem] overflow-hidden bg-cover">
                        <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:w-[36.25rem] leading-[2.938rem]">
                            {post.title_3.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <Link
                            to="/contact-us/"
                            className="inline-flex w-auto gap-5 items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base rounded-[59px] pt-[15px] pr-[69px] pb-[13px] pl-[42px] font-gorditamedium group ease-in duration-300 relative"
                        >
                            Get Started
                            <span className="inline-flex bg-primary justify-center items-center rounded-full h-11 w-11 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary absolute right-[5px] top-[5px]">
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

            {/* hiring developer section */}
            <section className="py-10 md:py-12 lg:py-14 overflow-hidden">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47rem] m-auto">
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium mb-5">
                            {post.title_4.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-[0.938rem] md:text-base leading-[1.875rem]">At Techuz, we pride ourselves on selecting only the top 3% of the talent. With a proven four-step process, we ensure that we're selecting the best candidates with the skills and expertise needed to deliver exceptional results.</p>
                    </div>

                    <ul className="grid grid-cols-1 lg:grid-cols-4 gap-x-[1.688rem] gap-y-[1.875rem] mt-10 relative z-10 before:content-['*'] before:h-[0.063rem] before:absolute before:w-[500%] before:bg-black before:top-8 before:left-[50%] before:-translate-x-1/2 before:-z-10 max-lg:before:w-[0.063rem] max-lg:before:max-lg:h-full max-lg:before:top-0 max-lg:before:left-6 max-lg:before:translate-x-0">
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">1</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Screening candidates</h4>

                            <span className="btch-big bg-[#E8E3FF] inline-block rounded-[6.25rem] text-[0.875rem] italicmb-[0.875rem] md:mb-5  font-gorditaregular sm:text-base  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]"><em className="font-gorditamedium">20%</em> of the candidates pass</span>

                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">We screen candidates based on their technical capabilities, experience, and past projects. We look for developers with a proven track record of success, as well as those who have experience in a range of tools and technologies.</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">2</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">In-depth review & test</h4>
                            <span className="btch-big bg-[#FFF2E3] inline-block rounded-[6.25rem] text-[0.875rem] italicmb-[0.875rem] md:mb-5  font-gorditaregular sm:text-base  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]"><em className="font-gorditamedium">7%</em> of the candidates pass</span>
                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Next, we conduct an in-depth skill review, communication and behavioral test to assess the candidate's communication skills, attitude, and ability to work effectively in a team environment.</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">3</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Live task</h4>

                            <span className="btch-big bg-[#E8FBFF] inline-block rounded-[6.25rem] text-[0.875rem] italicmb-[0.875rem] md:mb-5  font-gorditaregular sm:text-base  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]"><em className="font-gorditamedium">3.3%</em> of the candidates pass</span>

                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Then we assign them test projects to evaluate their real-world skills, competence, and professionalism. This allows us to assess their ability to work under pressure, meet tight deadlines, and deliver high-quality work that meets our clients' needs.</p>
                        </li>
                        <li className="relative pl-16 lg:pl-0 max-lg:bg-[#ffffff] bg-opacity-100">
                            <span className="max-lg:absolute max-lg:left-0 max-lg:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center mb-5 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">4</span>
                            <h4 className="font-blancomedium text-2xl mb-[0.875rem] md:mb-5">Consistent work</h4>

                            <span className="btch-big bg-[#E0FFEB] inline-block rounded-[6.25rem] text-[0.875rem] italicmb-[0.875rem] md:mb-5  font-gorditaregular sm:text-base  leading-[1.125rem] md:leading-[21px] px-[1.125rem] md:px-6 pt-[10px] pb-[7px]"><em className="font-gorditamedium">3%</em> of the candidates pass</span>

                            <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Finally, with the selected candidates we ensure they provide consistent exemplary work of delivering outstanding results time and time again.</p>
                        </li>
                    </ul>
                </div>
            </section>

            {/* gradient featured section */}
            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] bg-center rounded-[1.25rem] overflow-hidden">
                        <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:w-[36.063rem] leading-[2.938rem]">
                            {post.title_5.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                        </h2>
                        <Link
                            to="/contact-us/"
                            className="inline-flex w-auto gap-5 items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base rounded-[59px] pt-[15px] pr-[69px] pb-[13px] pl-[42px] font-gorditamedium group ease-in duration-300 relative"
                        >
                            Hire top engineers
                            <span className="inline-flex bg-primary justify-center items-center rounded-full h-11 w-11 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary absolute right-[5px] top-[5px]">
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

            {/* CTO section */}
            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47.5rem] mx-auto mb-[2.313rem] lg:mb-[4.375rem]">
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">
                            {post.title_6.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                    </div>

                    <div className="before:clear-both before:content-[''] before:block after:content-[''] after:block after:clear-both ">
                        <div className="lg:float-left p-10 md:p-[3.125rem] bg-[#F8F7F2] rounded-[1.25rem] lg:max-w-[27.313rem] lg:sticky md:top-24 md:left-0">
                            <p className="text-lg md:text-xl lg:text-[1.375rem] italic leading-[2.063rem]">
                                “{post.CTO_text[0]}”
                                <span className="block not-italic mt-[1.875rem] text-lg font-gorditamedium md:text-lg">
                                    {post.CTO_text[1]}
                                </span>
                            </p>
                        </div>
                        <div className="lg:float-right lg:max-w-[calc(100%-27.313rem)] lg:pl-20">
                            <div className="grid grid-cols-1 gap-y-[3.125rem] mt-10 md:mt-0">
                                <div className="">
                                    <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                                        <BatchIcon1 />
                                    </figure>
                                    <span className="inline-block italic py-2 pb-[0.438rem] px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Our engineers have extensive experience</span>
                                    <p className="text-[0.938rem] md:text-base leading-[1.625rem]">90% of our React developers are senior and mind-senior level with extensive experience of working on different projects, teams, and tools. You don’t require any hand-holding, rather they proactively take care of your development goals, adding value to your project from day one.</p>
                                </div>
                                <div className="">
                                    <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                                        <BatchIcon2 />
                                    </figure>
                                    <span className="inline-block italic py-2 pb-[0.438rem] px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#FFF2E3] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Our engineers stay for long</span>
                                    <p className="text-[0.938rem] md:text-base leading-[1.625rem]">Our commitment to long-term partnerships extends to both our clients and team members. With team members who have been with us since our inception, we have maintained a healthy employee turnover rate of 10 per cent. This provides our clients with the confidence to hire developers for extended projects and get seamless collaboration and consistent value.</p>
                                </div>
                                <div className="">
                                    <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                                        <BatchIcon3 />
                                    </figure>
                                    <span className="inline-block italic py-2 pb-[0.438rem] px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8FBFF] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Our engineers are problem solvers</span>
                                    <p className="text-[0.938rem] md:text-base leading-[1.625rem]">We deeply believe that the very essence of programming is problem-solving and that’s reflected in our developers’ work. We take a step further to the technical aspect and consider the real-world problems, solutions,  and user experience. With a problem-solving mindset we take more thoughtful and strategic approach to development, resulting in better outcomes for our clients.</p>
                                </div>
                                <div className="">
                                    <figure className="h-11 w-11 mb-5 md:mb-[1.875rem]">
                                        <BatchIcon4 />
                                    </figure>
                                    <span className="inline-block italic py-2 pb-[0.438rem] px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E0FFEB] text-xs md:text-sm lg:text-base mb-2 md:mb-5 font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">We prioritize learning & development</span>
                                    <p className="text-[0.938rem] md:text-base leading-[1.625rem]">We constantly work to enhance our developers’ expertise so that they are capable of tackling even the most complex tasks. Additionally, we prioritize the personal growth of our developers, ensuring that they have the resources and support they need to reach their full potential. This directly translates into better outcomes for our clients, who get consistently high-quality work and innovative solutions from our team.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* gradient featured section */}
            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] bg-center rounded-[1.25rem] overflow-hidden">
                        <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:w-[29.188rem] leading-[2.938rem]">
                            {post.title_7.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <Link
                            to="/contact-us/"
                            className="inline-flex w-auto gap-5 items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl md:text-base text-[15px] rounded-[59px] pt-[15px] md:pr-[69px] pb-[13px] md:pl-[42px] pl-[25px] pr-[65px] font-gorditamedium group ease-in duration-300 relative"
                        >
                            Supercharge your project
                            <span className="inline-flex bg-primary justify-center items-center rounded-full h-11 w-11 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary absolute right-[5px] top-[5px]">
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

            {/* hiring developer section */}
            <EcoSystem ecosystem={post.ecosystem} />

            {/* assemble your tem section */}
            <section className="py-10 md:py-12 lg:py-[3.438rem]">
                <div className="container">
                    <div className="title-sec text-center md:max-w-[47rem] m-auto">
                        <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium">
                            {post.title_8.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[1.875rem] mt-[2.313rem] md:mt-[3.125rem] text-center">
                        <div className="p-10 md:py-12 md:px-[3.625rem] border-2 border-[#FFF2E3] rounded-[1.25rem]">
                            <span className="inline-block italic py-2 pb-[0.313rem] px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-10 md:mb-[2.875rem] font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Hire on an hourly basis</span>
                            <div className="font-gorditamedium text-xl lg:text-2xl mb-5 md:mb-[86px]"><span className="text-[2.875rem] lg:text-[3.375rem]">$15</span>/Hour</div>

                            <ul className="text-base text-black leading-[1.625] font-gorditaregular text-left">
                                <li className="relative pl-7 mb-[0.625rem]">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> Pay only for hours worked
                                </li>
                                <li className="relative pl-7">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> Ideal if looking for time and material model
                                </li>
                            </ul>
                            <Link
                                to="/contact-us/"
                                className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-black shadow-1xl text-base leading-[1.313rem] rounded-3xl pl-6 py-1 pr-1 hover:border-primary font-gorditamedium group ease-in duration-300 mt-10 md:mt-[3.125rem]"
                            >
                                Get Started
                                <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                    <img
                                        src={ArrowWhite}
                                        alt=""
                                        className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                    />
                                    <img
                                        src={ArrowWhite}
                                        alt=""
                                        className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                    />
                                </span>
                            </Link>
                        </div>

                        <div className="p-10 md:py-12 md:px-[3.625rem] bg-[#F8F7F3] rounded-[1.25rem]">
                            <span className="inline-block italic py-2 pb-[0.313rem] px-[1.125rem] md:px-6 rounded-[6.25rem] bg-[#E8E3FF] text-xs md:text-sm lg:text-base mb-10 md:mb-[2.875rem] font-gorditamedium leading-[1.125rem] md:leading-[1.813rem]">Hire at a fixed monthly rate</span>
                            <div className="font-gorditamedium text-xl lg:text-2xl mb-[0.625rem]"><span className="text-[2.875rem] lg:text-[3.375rem]">$2200</span>/Month</div>
                            <span className="font-gorditamedium inline-block text-sm text-white bg-black italic py-1 pb-[0.125rem] pl-[0.813rem] pr-[1.063rem] rounded-[6.25rem] mb-[1.625rem] md:mb-10 leading-[1.813rem]">~ Save 10%</span>
                            <ul className="text-base text-black leading-[1.625] font-gorditaregular text-left">
                                <li className="relative pl-7 mb-[0.625rem]">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> 160 man-hours per month dedicated
                                </li>
                                <li className="relative pl-7">
                                    <span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 absolute top-[0.188rem] left-0"></span> Ideal if you need dedicated developers
                                </li>
                            </ul>
                            <Link
                                to="/contact-us/"
                                className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 border-white justify-center md:justify-start inline-white text-black border shadow-1xl text-base leading-[1.313rem] rounded-3xl pl-6 py-1 pr-1 hover:border-primary font-gorditamedium group ease-in duration-300 mt-10 md:mt-[3.125rem]"
                            >
                                Get Started
                                <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
                                    <img
                                        src={ArrowWhite}
                                        alt=""
                                        className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                    />
                                    <img
                                        src={ArrowWhite}
                                        alt=""
                                        className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                    />
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-8 lg:pb-10 xl:pb-20 lg:pt-[3.438rem] testimonial-sec md:py-[1.875rem]">
                <div className="container">
                    <div className="w-full text-center lg:pb-10 pb-9">
                        <div className="title-label text-center"><span className="font-gorditamedium inline-block bg-cream italic py-[0.563rem] pb-[0.375rem] px-[1.5rem] rounded-[6.25rem] mb-[0.625rem]">According to Clutch.co</span></div>
                        <h2 className="text-blacktext-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium lg:max-w-[47rem] my-0 mx-auto mb-5">
                            Techuz is one of the  <span className="italic">best development and IT firm</span> in the world
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-base lg:text-[1.125rem] lg:leading-[1.875rem]">And here’s what our clients say about us</p>
                    </div>
                    <TestimonialSlider />
                </div>
            </section >

            <section className="hiring-section bg-gradient-to-b from-[#F8F7F3] to-[rgba(248, 247, 243, 0.00)] pt-20 pb-10 lg:pt-[6.875rem] lg:pb-[3.75rem]">
                <div className="container">
                    <div className="title-wrap">
                        <h2 className="text-center text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] font-blancomedium lg:max-w-[47rem] my-0 mx-auto">
                            Here’s how  <span className="italic">hiring with Techuz</span> gives you the advantage you need
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                    </div>
                    <p className="usecase-title text-center text-lg md:text-xl lg:text-2xl font-gorditamedium leading-[1.45] pt-12 pb-10">Traditional Hiring VS Hiring with Techuz</p>

                    <div className="hiring-flow pb-[3.75rem]">
                        <div className="flow-title text-center">
                            <div className="shadow-1xl font-gorditaregular text-xs md:text-lg text-black rounded-full bg-white leading-[1.125rem] md:leading-[1.875rem] py-2 md:py-[0.875rem] px-7 md:px-[2.125rem] inline-block bg-white-200">Sourcing Developers</div>
                        </div>
                        <div className="flow-grid grid grid-cols-2">
                            <div className="flow-content text-right pr-[1.875rem] pt-[3.125rem] border-r border-r-[#DFDCD3] md:pb-20 pb-10">
                                <span className="flow-label inline-block border border-black rounded-[3.688rem] py-2 pb-[5px] px-[1.375rem] md:px-[1.563rem] text-xs md:text-sm leading-none text-center ml-auto mb-[1.688rem] md:mb-5">5 days</span>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] ml-auto relative before:content-[''] before:w-[0.438rem] before:h-[0.438rem] before:bg-[#DFDCD3] before:rounded before:absolute before:right-[-34px] before:top-[25%] before:translate-y-[-50%]">Posting job listings on various job boards</p>
                                <div className="content-box bg-white-200 shadow-1xl rounded-[0.625rem] p-[0.625rem] md:p-5 inline-block">
                                    <ul className="flex flex-wrap items-center justify-between">
                                        <li className="inline-block md:mr-[1.688rem] mb-1 md:mb-0">
                                            <StaticImage
                                                src="../../../assets/img/indeed.webp"
                                                alt="Awarded as top web developers in India 2019."
                                                placeholder="blurred"
                                            />
                                        </li>
                                        <li className="inline-blockmd:mr-[1.688rem] mb-1 md:mb-0">
                                            <StaticImage
                                                src="../../../assets/img/google-logo.webp"
                                                alt="Awarded as top web developers in India 2019."
                                                placeholder="blurred"
                                            />
                                        </li>
                                        <li className="inline-block md:mr-[1.688rem] mb-1 md:mb-0">
                                            <StaticImage
                                                src="../../../assets/img/linkedin-ful.webp"
                                                alt="Awarded as top web developers in India 2019."
                                                placeholder="blurred"
                                            />
                                        </li>
                                        <li className=" py-[0.375rem] px-[0.625rem] rounded-[3.75rem] text-xs text-black bg-[#F5F3FF] inline-block">other platforms</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flow-content right-align pl-[0.875rem] md:pl-[1.875rem] pt-[3.125rem]">
                                <span className="flow-label inline-block border border-black bg-cream rounded-[3.688rem] py-2 pb-[5px] px-[1.375rem] md:px-[1.563rem] text-xs md:text-sm leading-none  text-center ml-auto mb-5 italic">5 days</span>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] mr-auto">Tell us about your project, skills requirements, and team dynamics</p>
                            </div>
                        </div>

                        <div className="flow-title text-center ">
                            <div className="shadow-1xl font-gorditaregular text-xs md:text-lg text-black rounded-full bg-white leading-[1.125rem] md:leading-[1.875rem] py-2 md:py-[0.875rem] px-7 md:px-[2.125rem] inline-block bg-white-200">Screening</div>
                        </div>
                        <div className="flow-grid grid grid-cols-2">
                            <div className="flow-content text-right pr-[1.875rem] pt-[3.125rem] border-r border-r-[#DFDCD3] pb-[60px] sm:pb-[8.688rem]">
                                <span className="flow-label inline-block border border-black rounded-[3.688rem] py-2 pb-[5px] md:py-[0.563rem] px-[1.375rem] md:px-[1.563rem] text-xs md:text-sm leading-none text-center ml-auto mb-[1.688rem] md:mb-5">5 days</span>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] ml-auto relative before:content-[''] before:w-[0.438rem] before:h-[0.438rem] before:bg-[#DFDCD3] before:rounded before:absolute before:right-[-34px] before:top-[25%] before:translate-y-[-50%]">Screening through multiple applications to find the ideal candidates.</p>
                                <div className="content-box bg-white-200 shadow-1xl rounded-[0.625rem] p-[10px] sm:p-5 inline-block">
                                    <div className="candidate-img inline-block align-middle">
                                        <span className="img-container inline-block w-[2.625rem] h-[2.625rem] rounded-full border-2 border-white bg-white-300 overflow-hidden">
                                            <StaticImage
                                                src="../../../assets/img/candidate-1.webp"
                                                className="w-full h-full mt-[0.063rem] object-cover"
                                                alt="" />
                                        </span>
                                        <span className="img-container inline-block w-[2.625rem] h-[2.625rem] rounded-full border-2 border-white bg-white-300 overflow-hidden relative ml-[-0.75rem]">
                                            <StaticImage
                                                src="../../../assets/img/candidate-2.webp"
                                                className="w-full h-full mt-[0.063rem] object-cover"
                                                alt="" />
                                        </span>
                                        <span className="img-container inline-block w-[2.625rem] h-[2.625rem] rounded-full border-2 border-white bg-white-300 overflow-hidden relative ml-[-0.75rem]">
                                            <StaticImage
                                                src="../../../assets/img/candidate-3.webp"
                                                className="w-full h-full mt-[0.063rem] object-cover"
                                                alt="" />
                                        </span>
                                    </div>
                                    <p className="text-sm sm:text-base text-black inline-block align-middle pl-[0.625rem] max-md:text-center"><strong className="font-gorditabold">1000</strong> Applied Candidates</p>
                                </div>
                            </div>
                            <div className="flow-content right-align pl-[0.875rem] md:pl-[1.875rem] pt-[3.125rem]">
                                <span className="flow-label inline-block border border-black bg-cream rounded-[3.688rem] py-2 pb-[5px] px-[1.375rem] md:px-[1.563rem] text-xs md:text-sm leading-none  text-center ml-auto mb-5 italic">1 day</span>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] mr-auto">We’ll contact you list of our suitable expert developers matching your requirements.</p>
                                <div className="content-box bg-white-200 shadow-1xl rounded-[0.625rem] p-[0.875rem] md:p-5 inline-block max-md:min-w-0 min-w-[18.75rem] mt-[15px] sm:mt-0">
                                    <div className="img-container w-[2.688rem] h-[2.688rem] md:w-[3.875rem] md:h-[3.875rem] bg-[#f7f8f9] rounded-full overflow-hidden align-middle inline-block">
                                        <StaticImage
                                            src="../../../assets/img/expert-img.webp"
                                            className=" mt-[0.063rem] object-contain w-full h-full"
                                            alt="" />
                                    </div>
                                    <div className="candidate-detail align-middle inline-block md:pl-4 pt-[9px] relative">
                                        <span className="joined-label absolute top-[-68px] sm:top-[-35px] left-0 w-[8.5rem]">
                                            <StaticImage
                                                src="../../../assets/img/expert-label.webp"
                                                className="w-[130px] sm:w-[160px] h-[25px] object-contain"
                                                imgClassName="object-contain"
                                                alt="" />
                                        </span>
                                        <p className="job-position text-xs md:text-sm leading-[15px]">Senior React Developer</p>
                                        <span className="experience text-[#A0A0A0] font-gorditamedium italic text-xs">15+ yr exp.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flow-title text-center ">
                            <div className="shadow-1xl font-gorditaregular text-xs md:text-lg text-black rounded-full bg-white leading-[1.125rem] md:leading-[1.875rem] py-2 md:py-[0.875rem] px-7 md:px-[2.125rem] inline-block bg-white-200">Interviewing</div>
                        </div>
                        <div className="flow-grid grid grid-cols-2">
                            <div className="flow-content text-right pr-[1.875rem] pt-[3.125rem] border-r border-r-[#DFDCD3] pb-[10.625rem]">
                                <span className="flow-label inline-block border border-black rounded-[3.688rem] py-2 pb-[5px] md:py-[0.563rem] px-[1.375rem] md:px-[1.563rem] text-xs md:text-sm leading-none text-center ml-auto mb-[1.688rem] md:mb-5">10 days</span>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] ml-auto relative before:content-[''] before:w-[0.438rem] before:h-[0.438rem] before:bg-[#DFDCD3] before:rounded before:absolute before:right-[-34px] before:top-[25%] before:translate-y-[-50%]">Coordination with candidates' schedules</p>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] ml-auto relative before:content-[''] before:w-[0.438rem] before:h-[0.438rem] before:bg-[#DFDCD3] before:rounded before:absolute before:right-[-34px] before:top-[25%] before:translate-y-[-50%]">Setting up and conducting multiple rounds of interviews</p>
                                {/* <div className="content-box bg-white-200 shadow-1xl rounded-[0.625rem] p-5 inline-block  max-lg:h-[5.625rem] max-lg:w-[9.813rem] lg:min-w-[23.313rem]"></div> */}
                            </div>
                            <div className="flow-content right-align pl-[0.875rem] md:pl-[1.875rem] pt-[3.125rem]">
                                <span className="flow-label inline-block border border-black bg-cream rounded-[3.688rem] py-2 pb-[5px] px-[1.375rem] md:px-[1.563rem] text-xs md:text-sm leading-none  text-center ml-auto mb-5 italic">2 days</span>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] max-w-[27.75rem] mr-auto md:mb-5">Developers pre-vetted with rigorous interview accessing skills on multiple fronts</p>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] mr-auto">Faster technical evaluation and other assessments</p>

                                <div className="content-box bg-white-200 shadow-1xl rounded-[0.625rem] p-[0.875rem] md:p-5 inline-block max-md:min-w-0 min-w-[18.75rem]">
                                    <div className="img-container w-[2.688rem] h-[2.688rem] md:w-[3.875rem] md:h-[3.875rem] bg-[#f7f8f9] rounded-full overflow-hidden align-middle inline-block">
                                        <StaticImage
                                            src="../../../assets/img/expert-img.webp"
                                            className=" mt-[0.063rem] object-contain w-full h-full"
                                            alt="" />
                                    </div>
                                    <div className="candidate-detail align-middle inline-block md:pl-4 pt-[9px] relative">
                                        <span className="joined-label absolute top-[-68px] sm:top-[-25px] md:top-[-35px] left-0 w-[8.5rem]">
                                            <StaticImage
                                                src="../../../assets/img/expert-label.webp"
                                                className="w-[130px] h-[25px] object-contain"
                                                imgClassName="object-contain"
                                                alt="" />
                                        </span>
                                        <p className="job-position text-xs md:text-sm leading-[15px]">Senior React Developer</p>
                                        <span className="experience text-[#A0A0A0] font-gorditamedium italic text-xs">15+ yr exp.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flow-title text-center ">
                            <div className="shadow-1xl font-gorditaregular text-xs md:text-lg text-black rounded-full bg-white leading-[1.125rem] md:leading-[1.875rem] py-2 md:py-[0.875rem] px-7 md:px-[2.125rem] inline-block bg-white-200">Onboarding</div>
                        </div>
                        <div className="flow-grid grid grid-cols-2">
                            <div className="flow-content text-right pr-[1.875rem] pt-[3.125rem] border-r border-r-[#DFDCD3] md:pb-20 pb-10">
                                <span className="flow-label inline-block border border-black rounded-[3.688rem] py-2 pb-[5px] px-[1.375rem] md:px-[1.563rem] text-xs md:text-sm leading-none text-center ml-auto mb-[1.688rem] md:mb-5">10 days</span>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] ml-auto relative before:content-[''] before:w-[0.438rem] before:h-[0.438rem] before:bg-[#DFDCD3] before:rounded before:absolute before:right-[-34px] before:top-[25%] before:translate-y-[-50%]">Sending offer to candidates and waiting to join</p>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] ml-auto relative before:content-[''] before:w-[0.438rem] before:h-[0.438rem] before:bg-[#DFDCD3] before:rounded before:absolute before:right-[-34px] before:top-[25%] before:translate-y-[-50%]">May included drop rates with candidates</p>

                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] ml-auto relative before:content-[''] before:w-[0.438rem] before:h-[0.438rem] before:bg-[#DFDCD3] before:rounded before:absolute before:right-[-34px] before:top-[25%] before:translate-y-[-50%]">Preparing paperwork and contracts each candidates if hiring multiple</p>
                                <div className="content-box bg-white-200 shadow-1xl rounded-[0.625rem] p-5 inline-block relative mt-[10px] sm:mt-0">
                                    <div className="candidate-img inline-block align-middle">
                                        <span className="img-container inline-block w-[2.625rem] h-[2.625rem] rounded-full border-2 border-white bg-white-300 overflow-hidden">
                                            <StaticImage
                                                src="../../../assets/img/candidate-1.webp"
                                                className="w-full h-full mt-[0.063rem] object-cover"
                                                alt="" />
                                        </span>
                                        <span className="img-container inline-block w-[2.625rem] h-[2.625rem] rounded-full border-2 border-white bg-white-300 overflow-hidden relative ml-[-0.75rem]">
                                            <StaticImage
                                                src="../../../assets/img/candidate-2.webp"
                                                className="w-full h-full mt-[0.063rem] object-cover"
                                                alt="" />

                                        </span>
                                    </div>
                                    <p className="text-sm sm:text-base text-black inline-block align-middle pl-[0.625rem] max-md:text-center">Candidates have <strong className="font-gorditabold"><i>not joined</i></strong> </p>
                                    <span className="joined-label absolute top-[-10px] left-[50%] translate-x-[-50%]">
                                        <StaticImage
                                            src="../../../assets/img/drop-rate.webp"
                                            className="w-[95px] h-[25px]"
                                            alt="" />
                                    </span>
                                </div>
                            </div>

                            <div className="flow-content right-align pl-[0.875rem] md:pl-[1.875rem] pt-[3.125rem]">
                                <span className="flow-label inline-block border border-black bg-cream rounded-[3.688rem] py-2 pb-[5px] px-[1.375rem] md:px-[1.563rem] text-xs md:text-sm leading-none  text-center ml-auto mb-5 italic">2 days</span>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] mr-auto">Developers are ready to integrate into your project</p>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] mr-auto">They proactively take care of development goals, adding value to your project from day one</p>

                                {/* <div className="content-box bg-white-200 shadow-1xl rounded-[0.625rem] p-5 inline-block  max-lg:h-[5.625rem] max-lg:w-[9.813rem] lg:min-w-[23.313rem]"></div> */}
                            </div>
                        </div>


                        <div className="flow-title text-center ">
                            <div className="shadow-1xl font-gorditaregular text-xs md:text-lg text-black rounded-full bg-white leading-[1.125rem] md:leading-[1.875rem] py-2 md:py-[0.875rem] px-7 md:px-[2.125rem] inline-block bg-white-200">Scaling up or Scaling down</div>
                        </div>

                        <div className="flow-grid grid grid-cols-2">
                            <div className="flow-content text-right pr-[1.875rem] pt-[3.125rem] border-r border-r-[#DFDCD3]">
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] md:mb-[1.875rem] max-w-[27.75rem] ml-auto relative before:content-[''] before:w-[0.438rem] before:h-[0.438rem] before:bg-[#DFDCD3] before:rounded before:absolute before:right-[-34px] before:top-[25%] before:translate-y-[-50%] mb-[3.375rem]">Repeat the hiring process</p>
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] ml-auto relative before:content-[''] before:w-[0.438rem] before:h-[0.438rem] before:bg-[#DFDCD3] before:rounded before:absolute before:right-[-34px] before:top-auto before:bottom-[-0.625rem] before:translate-y-[-50%]">Might have to fire the candidate</p>
                            </div>

                            <div className="flow-content right-align pl-[0.875rem] md:pl-[1.875rem] pt-[3.125rem]">
                                <p className="text-base md:text-lg lg:text-2xl leading-6 md:leading-[2.125rem] mb-[0.625rem] md:mb-[1.875rem] max-w-[27.75rem] mr-auto">Just notify to add or remove the developers as per your requirements</p>
                            </div>
                        </div>

                    </div>

                    <div className="hiring-table md:pt-[3.75rem]">
                        <div className="table-head">
                            <ul className="grid grid-cols-2 md:grid-cols-3 mb-5 justify-center items-center">
                                <li className="max-md:hidden"></li>
                                <li className="text-left">
                                    <span className="font-gorditamedium inline-block bg-cream italic pt-[12px] pb-[9px] sm:px-[1.625rem] px-4 rounded-[6.25rem] leading-[1.125rem] md:leading-[21px] text-sm sm:text-base text-center h-full md:flex md:flex-wrap md:items-center md:justify-center max-[767px]:max-w-[150px] md:max-w-[200px]">Traditional hiring</span>
                                </li>
                                <li className="text-right">
                                    <span className="font-gorditamedium inline-block bg-[#E8E3FF] italic pt-[12px] pb-[9px] sm:px-[1.625rem] px-4 rounded-[6.25rem] leading-[1.125rem] md:leading-[21px] text-sm sm:text-base md:text-center text-left">Hiring developers with Techuz</span>
                                </li>
                            </ul>
                        </div>
                        <div className="table-body md:rounded-[1.25rem] md:border border-black overflow-hidden relative before:content-[''] before:w-[1px] before:h-full before:bg-[url('../assets/img/grad-line.webp')] md:before:bg-center before:absolute before:right-[33.33%] before:top-[50%] before:translate-y-[-50%] before:bg-no-repeat max-md:before:hidden">
                            <ul className="grid grid-cols-2 md:grid-cols-3">
                                <li className="text-sm md:text-sm lg:text-xl italic py-[0.625rem] md:py-[0.938rem] md:pt-[30px] font-gorditamedium leading-[1.313rem] md:leading-[1.688rem] text-black relative pl-0 sm:pl-[2.313rem] max-md:col-span-2 flex gap-[0.313rem] md:gap-[1.188rem]"><span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 block md:mt-1"></span>Hiring time</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem]  md:pt-[30px] bg-[#F8F7F3] px-6">30 days</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem]  md:pt-[30px] bg-[#F8F7F3] px-6">5-7 days</li>
                            </ul>
                            <ul className="grid grid-cols-2 md:grid-cols-3">
                                <li className="text-sm md:text-sm lg:text-xl italic py-[0.625rem] md:py-[0.938rem] font-gorditamedium leading-[1.313rem] md:leading-[1.688rem] text-black relative pl-0 sm:pl-[2.313rem] max-md:col-span-2 flex gap-[0.313rem] md:gap-[1.188rem]"><span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 block md:mt-1"></span>Hiring cost</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem} bg-[#F8F7F3] px-6">High</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem} bg-[#F8F7F3] px-6">None</li>
                            </ul>
                            <ul className="grid grid-cols-2 md:grid-cols-3">
                                <li className="text-sm md:text-sm lg:text-xl italic py-[0.625rem] md:py-[0.938rem] font-gorditamedium leading-[1.313rem] md:leading-[1.688rem] text-black relative pl-0 sm:pl-[2.313rem] max-md:col-span-2 flex gap-[0.313rem] md:gap-[1.188rem]"><span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 block md:mt-1"></span>Flexibility</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem] bg-[#F8F7F3] px-4 sm:px-6">None</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem] bg-[#F8F7F3] px-4 sm:px-6">One email or call to scale up/down</li>
                            </ul>
                            <ul className="grid grid-cols-2 md:grid-cols-3">
                                <li className="text-sm md:text-sm lg:text-xl italic py-[0.625rem] md:py-[0.938rem] font-gorditamedium leading-[1.313rem] md:leading-[1.688rem] text-black relative pl-0 sm:pl-[2.313rem] max-md:col-span-2 flex gap-[0.313rem] md:gap-[1.188rem]"><span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 block md:mt-1"></span>Trial</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem] bg-[#F8F7F3] px-4 sm:px-6">None</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem] bg-[#F8F7F3] px-4 sm:px-6"><span className="font-gorditamedium inline-block text-[12px] sm:text-sm text-white bg-black italic pb-[3px] pt-[4px] pl-[10px] pr-[10px] rounded-[6.25rem] leading-[1.813rem]">~ 7 days free trial</span></li>
                            </ul>
                            <ul className="grid grid-cols-2 md:grid-cols-3">
                                <li className="text-sm md:text-sm lg:text-xl italic py-[0.625rem] md:py-[0.938rem] font-gorditamedium leading-[1.313rem] md:leading-[1.688rem] text-black relative pl-0 sm:pl-[2.313rem] max-md:col-span-2 flex gap-[0.313rem] md:gap-[1.188rem]"><span className="bg-[url('../assets/img/tick-circle.svg')] bg-center bg-no-repeat bg-contain h-4 w-4 block md:mt-1"></span>Compensation considerations</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem] bg-[#F8F7F3] px-4 sm:px-6">Salary, benefits,hikes</li>
                                <li className="text-center text-base md:text-lg lg:text-xl leading-6 md:leading-[1.688rem] py-[1.125rem] md:py-[0.938rem] md:pb-[30px] bg-[#F8F7F3] px-4 sm:px-6">Fixed, simple, and transparent invoice</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="lets-get-started pt-10 lg:pt-[6.875rem] pb-20 lg:pb-[3.438rem]">
                <div className="container">
                    <div className="title-wrap mb-[3.125rem]">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-0 font-blancomedium text-center">Let’s get started in  <span className="italic text-primary">3 steps</span> <span className="inline-block text-orangecol">.</span></h2>
                    </div>
                    <div className="steps-grid grid md:grid-cols-3 gap-10">
                        <div className="steps-item md:text-center relative before:content-[''] before:w-full before:h-[0.063rem] before:bg-black before:absolute max-lg:before:top-6 before:top-[1.875rem] before:z-[-1] before:left-[calc(50%+20px)] max-md:before:h-[calc(100%+20px)] max-md:before:w-[0.063rem] max-md:before:left-6 max-md:translate-x-0 max-md:pl-16">
                            <span className="max-md:absolute max-md:left-0 max-md:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center my-0 mx-auto mb-10 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">1</span>
                            <h3 className="text-lg md:text-xl lg:text-2xl md:leading-[1.688rem] lg:leading-[2.188rem] mb-[0.625rem] md:mb-5 font-gorditamedium">Share your developer requirements</h3>
                            <p className="leading-[1.625rem] ">Tell us about your project, skills requirements, and team dynamics. </p>
                        </div>
                        <div className="steps-item md:text-center relative before:content-[''] before:w-full before:h-[0.063rem] before:bg-black before:absolute max-lg:before:top-6 before:top-[1.875rem] before:z-[-1] before:left-[calc(50%+20px)] max-md:before:h-[calc(100%+20px)] max-md:before:w-[0.063rem] max-md:before:left-6 max-md:translate-x-0 max-md:pl-16">
                            <span className="max-md:absolute max-md:left-0 max-md:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center my-0 mx-auto mb-10 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">2</span>
                            <h3 className="text-lg md:text-xl lg:text-2xl md:leading-[1.688rem] lg:leading-[2.188rem] mb-[0.625rem] md:mb-5 font-gorditamedium">Interview and choose your developers</h3>
                            <p className="leading-[1.625rem] ">Get to know and evaluate our developers and just select the perfect match for your team and requirements. </p>
                        </div>
                        <div className="steps-item md:text-center relative max-md:pl-16">
                            <span className="max-md:absolute max-md:left-0 max-md:-top-2 h-[3rem] w-[3rem] lg:h-[3.75rem] lg:w-[3.75rem] border border-black rounded-[50%] flex items-center justify-center my-0 mx-auto mb-10 bg-[#ffffff] font-blancomedium text-xl lg:text-2xl leading-[2.188rem]">3</span>
                            <h3 className="text-lg md:text-xl lg:text-2xl md:leading-[1.688rem] lg:leading-[2.188rem] mb-[0.625rem] md:mb-5 font-gorditamedium">Hire and achieve your project goals</h3>
                            <p className="leading-[1.625rem] ">Get the developers on board who’ll work like your in-house team to help you achieve your project goals. </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-10 md:py-12 lg:py-14">
                <div className="container">
                    <div className="flex flex-wrap border border-cream items-center justify-between gap-10 px-[1.875rem] pt-10 pb-[3.125rem] md:pt-[3.25rem] md:pb-[3.625rem] md:px-[4.375rem] md:bg-[url('../assets/img/tech-bg.webp')] bg-[url('../assets/img/tech-bg-mob.webp')] bg-center rounded-[1.25rem] overflow-hidden">
                        <h2 className="font-blancomedium text-[1.75rem] md:text-3xl lg:text-4xl w-full md:w-[36.25rem] leading-[2.938rem]">
                            {post.title_3.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <Link
                            to="/contact-us/"
                            className="inline-flex w-auto gap-5 items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-white shadow-1xl text-base rounded-[59px] pt-[15px] pr-[55px] md:pr-[69px] pb-[13px] pl-[15px] md:pl-[42px] font-gorditamedium group ease-in duration-300 relative"
                        >
                            Share your requirements
                            <span className="inline-flex bg-primary justify-center items-center rounded-full h-11 w-11 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary absolute right-[5px] top-[5px]">
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
                                />
                                <img
                                    src={ArrowWhite}
                                    alt=""
                                    className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
                                />
                            </span>
                        </Link>
                    </div>
                </div>
            </section>

            {/* <end /> */}
            <Faq faqData={post.faqs} />

            <div className="guide-section">
                <div className="container">
                    <div className="guide-grid grid md:grid-cols-[230px_1fr] md:gap-x-6 lg:gap-x-[5.563rem]">
                        <div className="max-md:hidden content-table lg:pt-[16.375rem] border-r border-r-[#DFDCD3]">
                            <div className="sticky-content sticky top-[4.688rem]">
                                <h3 className=" text-[1.375rem] font-blancomedium leading-[2.25rem]">Table of Contents<span className="inline-block text-orangecol">.</span></h3>
                                <ul className="mt-[1.875rem] ">
                                    {
                                        post.guide.tableOfContent.map((content, idx) => (
                                            <li key={idx} className="mb-5">
                                                <Link
                                                    to={content[0]}
                                                    className={`inline-block w-full hover:italic pr-10 hover:font-gorditamedium hover:underline transition duration-150 ease-out before:content-[''] before:w-[0.188rem] before:bg-orangecol before:absolute relative before:rounded-[3.75rem] before:h-5 before:top-0 before:right-0 before:opacity-0 hover:before:opacity-100 ${elementInViewPort == idx + 1 ? 'before:opacity-100 font-gorditamedium underline italic' : ''}`}
                                                >{content[1]}</Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div id="section1" className="content-detail md:py-[6.875rem] py-20">
                            {/* Guides title starts */}
                            <div className="title-wrap mb-[2.938rem] md:mb-[3.125rem]">
                                <h2 className="text-black text-[2rem] lg:text-[2.625rem] leading-[2.813rem] lg:leading-[3.375rem] pb-0 font-blancomedium">
                                    {post.guide.title.map((text, idx) => text[0] ? <span key={idx} className={text[0]}>{text[1]}</span> : text[1])}
                                    <span className="inline-block text-orangecol">.</span>
                                </h2>
                            </div>
                            {/* Guides title ends */}

                            <div className="scrollable-content md:mt-[4.375rem]">
                                {/* Overview starts */}
                                <h3 className="text-[2rem] lg:text-4xl font-blancomedium mb-5">
                                    {post.guide.content.overview.title}
                                </h3>
                                <p className="text-lg leading-[1.875rem] mb-10">
                                    {post.guide.content.overview.description}
                                </p>
                                {
                                    post.guide.content.overview.details.map(({ title, description }, idx) => (
                                        <div key={idx}>
                                            <h4 className="font-gorditamedium text-xl leading-[1.688rem] mb-2">{title}</h4>
                                            <p className="text-lg leading-[1.875rem] mb-[1.875rem]">{description}</p>
                                        </div>
                                    ))
                                }
                                {/* Overview ends */}

                                {/* Steps to hire starts */}
                                <p id="section2" className=" scroll-m-16 scroll-smooth"></p>

                                <h3 className="text-4xl font-blancomedium mb-5 mt-[4.375rem]">
                                    {post.guide.content.stepsToHire.title}
                                </h3>
                                <p className="text-lg leading-[1.875rem] mb-10">
                                    {post.guide.content.stepsToHire.description}
                                </p>
                                {
                                    post.guide.content.stepsToHire.details.map(({ title, description }, idx) => (
                                        <div key={idx}>
                                            <h4 className="font-gorditamedium text-xl leading-[1.688rem] mb-2 mt-6">
                                                <span className="pr-[0.688rem]">{idx + 1}.</span> {title}
                                            </h4>
                                            <p className="text-lg leading-[1.875rem] mb-10">
                                                {description}
                                            </p>
                                        </div>
                                    ))
                                }
                                {/* Steps to hire ends */}

                                {/* Hard skills starts */}
                                <p id="section3" className=" scroll-m-16 "></p>

                                <h3 className="text-4xl font-blancomedium mb-5 mt-[4.375rem]">
                                    {post.guide.content.hardSkills.title}
                                </h3>
                                <p className="text-lg leading-[1.875rem] mb-10">
                                    {post.guide.content.hardSkills.description}
                                </p>
                                {
                                    post.guide.content.hardSkills.details.map(({ title, points }, idx) => (
                                        <div key={`hardskill-${idx}`}>
                                            <h4 className=" text-[1.75rem] font-blancomedium mb-[1.875rem]">
                                                {title}
                                            </h4>
                                            {points.map(({ subtitle, text }, idx) => (
                                                <div key={`point-${idx}`}>
                                                    <h5 className="font-gorditamedium text-lg leading-[1.688rem] mb-2">
                                                        {subtitle}
                                                    </h5>
                                                    <p className="text-lg leading-[1.875rem] mb-[1.875rem]">
                                                        {text}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                }
                                {/* Hard skills ends */}

                                {/* Soft skills starts */}
                                <p id="section4" className=" scroll-m-16 "></p>

                                <h3 className="text-4xl font-blancomedium mb-5 mt-[4.375rem]">
                                    {post.guide.content.softSkills.title}
                                </h3>
                                <p className="text-lg leading-[1.875rem] mb-10">
                                    {post.guide.content.softSkills.description}
                                </p>
                                {post.guide.content.softSkills.details.map(({ title, description }, idx) => (
                                    <div key={idx}>
                                        <h5 className="font-gorditamedium text-lg leading-[1.688rem] mb-2">
                                            {title}
                                        </h5>
                                        <p className="text-lg leading-[1.875rem] mb-[1.875rem]">
                                            {description}
                                        </p>
                                    </div>
                                ))}
                                {/* Soft skills ends */}

                                {/* Interview questions starts */}
                                <p id="section5" className=" scroll-m-16 "></p>

                                <h3 className="text-4xl font-blancomedium mb-[3.125rem] mt-[4.375rem]">
                                    {post.guide.content.interviewQuestions.title}
                                </h3>
                                {post.guide.content.interviewQuestions.qnas.map(({ question, answer: { text, points } }, idx) => (
                                    <div key={`qna-${idx}`}>
                                        <h5 className="font-gorditamedium text-lg leading-[1.688rem] mb-2">
                                            {question}
                                        </h5>
                                        <div className="text-lg leading-[1.875rem] mb-[1.875rem]">
                                            {text}
                                            <ul className=" pl-[0.313rem] ">
                                                {points.map((point, idx) => (
                                                    <li key={`point-${idx}`} className="text-lg leading-[1.875rem] mb-[0.375rem] relative before:content-[''] before:w-[0.375rem] before:h-[0.375rem] before:rounded-full before:bg-[#DFDCD3] before:absolute before:left-0 before:top-[0.625rem] pl-[1.188rem]">
                                                        {point}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                                {/* Interview questions ends */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export const Head = () => <Seo title="Hire React Developers USA" noIndex />

export default HireReactDevelopersUSA
