import React, { useEffect, useState } from "react"
import { useContextValues } from "../../context/NavBarContext"
import useFormControls from "../../hooks/useContactFormControls"
import AdditionalInfoForm from "../AdditionalInfoForm"
import {
  Budget,
  Email,
  FlagsDropDown,
  Name,
  PhoneNumber,
  Recaptcha,
  TextArea,
} from "../FormFields"
import { Currency } from "../FormFields/Budget"

type Props = {
  spacingCss: string
  submitBtnCss: string
  submitBtnTxt?: string
  currency?: Currency
  formCss?: string
  NameCss?: string
  emailCss?: string
  phoneNumberCss?: string
  budgetCss?: string
  textAreaCss?: string
  formTitle?: string
}

export default ({
  spacingCss,
  submitBtnCss,
  submitBtnTxt = "Submit and get a quote within 24 hours",
  currency = "USD",
  formCss,
  NameCss,
  emailCss,
  phoneNumberCss,
  budgetCss,
  textAreaCss,
  formTitle,
}: Props) => {
  const {
    handleChange,
    errorMessage,
    formValue,
    handleSubmit: handleContFormSubmit,
    handleToken,
    flags,
    recaptchaRef,
    customPhoneDropdownRef,
    selectedFlagRef,
    toggleDropdown,
    setSelectedCountryDialCode,
    renderRecaptcha,
    setToggleDropdown,
    shouldRenderRecaptcha,
    selctedCountryFlag,
    setSelectedCountryFlag,
    updateFormData,
    isFormValuesValid,
  } = useFormControls()

  const { currentPath } = useContextValues()
  const [showAdditionalInfoForm, setShowAdditionalInfoForm] = useState(false)
  const [displayAdditionalInfo, setDisplayAdditionalInfo] = useState(false)
  const handleSubmit = async e => {
    e.preventDefault()
    if (displayAdditionalInfo) {
      if (isFormValuesValid(["country"])) {
        updateFormData(currentPath)
        setShowAdditionalInfoForm(prev => !prev)
      }
    } else {
      await handleContFormSubmit(e, {
        pathName: currentPath,
        ignoreFields: ["country"]
      })
    }
  }

  useEffect(() => {
    setDisplayAdditionalInfo(currentPath === "/web-design-company/")
  }, [currentPath])

  return showAdditionalInfoForm ? (
    <AdditionalInfoForm
      formCss={formCss}
      spacingCss={spacingCss}
      submitBtnCss={submitBtnCss}
    />
  ) : (
    <>
      {formTitle && (
        <h1 className="md:text-[1.75rem] text-[1.5rem] md:leading-[2.438rem] leading-[1.8rem] pb-[0.625rem] font-blancotrialbold text-center bg-[#fff] mb-[-2.5rem] pt-[2rem] rounded-xl">
          {formTitle}
        </h1>
      )}
      <form
        className={`contact-form-main contact-form-page ${formCss || "bg-white-200 rounded-xl p-6 md:px-10 px-6 pb-10 pt-8"
          } mt-5`}
        onSubmit={handleSubmit}
        onChange={shouldRenderRecaptcha}
      >
        <div
          className={`relative flex justify-between ${spacingCss} pb-0 md:flex-nowrap flex-wrap`}
        >
          <div className=" w-full md:pb-0 pb-5">
            <Name
              fieldCss={`${NameCss ||
                "text-lg w-full text-black py-4 px-7 max-[1440px]:py-[0.75rem] bg-transparent rounded-md border border-[#C8CBD2] foucs-visible:border-[#C8CBD2]"
                } focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none`}
              errorCss="text-[0.75rem] text-red absolute bottom-0"
              formValue={formValue}
              handleChange={handleChange}
              errorMessage={errorMessage}
            />
          </div>
        </div>
        <div
          className={`flex justify-between ${spacingCss} pb-0 lg:flex-nowrap flex-wrap`}
        >
          <div className="relative lg:max-w-[calc(50%-0.313rem)] max-w-full w-full lg:pb-0 pb-5">
            <Email
              fieldCss={`${emailCss ||
                "text-lg w-full text-black py-4 px-7 max-[1440px]:py-[0.75rem] bg-transparent rounded-md border border-[#C8CBD2] foucs-visible:border-[#C8CBD2]"
                } focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none`}
              errorCss="text-[0.75rem] text-red absolute lg:bottom-[-18px] bottom-0"
              formValue={formValue}
              handleChange={handleChange}
              errorMessage={errorMessage}
            />
          </div>

          <div className="relative lg:max-w-[calc(50%-0.313rem)] max-w-full w-full md:pb-0 pb-5">
            <FlagsDropDown
              selectedFlagRef={selectedFlagRef}
              setToggleDropdown={setToggleDropdown}
              selctedCountryFlag={selctedCountryFlag}
            />
            <PhoneNumber
              fieldCss={`${phoneNumberCss ||
                "pl-[47px] text-lg w-full text-black py-4 px-7 max-[1440px]:py-[0.75rem] bg-transparent rounded-md border border-[#C8CBD2] foucs-visible:border-[#C8CBD2]"
                } focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none`}
              errorCss="text-[0.75rem] text-red absolute lg:bottom-[-18px] md:bottom-[-20px] bottom-0"
              toggleDropdown={toggleDropdown}
              flags={flags}
              customPhoneDropdownRef={customPhoneDropdownRef}
              setSelectedCountryFlag={setSelectedCountryFlag}
              setSelectedCountryDialCode={setSelectedCountryDialCode}
              setToggleDropdown={setToggleDropdown}
              handleChange={handleChange}
              errorMessage={errorMessage}
            />
          </div>
        </div>
        <div
          className={`relative flex justify-between ${spacingCss} pb-0 md:flex-nowrap flex-wrap relative`}
        >
          <div className=" w-full md:pb-0 pb-5">
            <Budget
              fieldCss={`select-budget bg-[url('../assets/img/Path1.svg')] bg-no-repeat bg-[center_right_15px] ${budgetCss ||
                "text-black py-4 px-7 bg-transparent rounded-md border border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                } 
            appearance-none w-full text-lg w-full text-black py-4 px-7 max-[1440px]:py-[0.75rem] bg-transparent rounded-md border border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2] appearance-none`}
              errorCss="text-[0.75rem] text-red absolute lg:bottom-0 bottom-0"
              formValue={formValue}
              currency={currency}
              handleChange={handleChange}
              errorMessage={errorMessage}
            />
          </div>
        </div>
        <div
          className={`relative flex justify-between ${spacingCss} pb-0 md:flex-nowrap flex-wrap`}
        >
          <div className=" w-full md:pb-0 pb-5">
            <TextArea
              fieldCss={`align-top h-40 max-[1440px]:h-24 resize-none text-lg w-full text-black ${textAreaCss ||
                "py-4 px-7 max-[1440px]:py-[0.75rem] bg-transparent rounded-md border border-[#C8CBD2] focus:border focus:outline-none foucs:border-b-[#C8CBD2] focus-visible:outline-none foucs-visible:border-[#C8CBD2]"
                }`}
              errorCss="text-[0.75rem] text-red absolute lg:bottom-0 bottom-0"
              formValue={formValue}
              handleChange={handleChange}
              errorMessage={errorMessage}
              name="message"
              placeholder="Describe your project briefly"
            />
          </div>
        </div>
        {renderRecaptcha && (
          <div
            className={`flex justify-between pb-5 md:flex-nowrap flex-wrap relative pt-0 scale-90 origin-[0_0] md:transform-none`}
          >
            <Recaptcha
              errorCss="absolute text-[0.75rem] text-red bottom-[1px]"
              recaptchaRef={recaptchaRef}
              handleToken={handleToken}
              errorMessage={errorMessage}
            />
          </div>
        )}
        <button className={submitBtnCss}>
          {displayAdditionalInfo ? "Inquiry now" : submitBtnTxt}
        </button>
      </form>
    </>
  )
}
