import React from "react"

export default function Modal({ showModal, children }) {
  if (showModal)
    return (
      <div
        className="relative z-[99999999]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-4">
            <div className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-4 sm:w-full sm:max-w-[50rem]">
              <div className="bg-gray-50">{children}</div>
            </div>
          </div>
        </div>
      </div>
    )
  return null
}
